const passwordIcon = document.getElementById('password-icon');

passwordIcon.addEventListener('click', function() {
    const passwordField = passwordIcon.closest('.input').querySelector('.input__field');

    if (passwordField.getAttribute('type') === 'password') {
        passwordField.setAttribute('type', 'text');
        passwordIcon.classList.add('input__password_show');
    } else {
        passwordField.setAttribute('type', 'password');
        passwordIcon.classList.remove('input__password_show');
    }
}, false);

const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
